import axios from "axios";

const baseurl = "https://keuangan.psppcdn.com/kidora";

export const apiPost = async (url, param) => {
  const r = await axios.post(baseurl + url, param);
  return r.data;
};

export const apiGet = async (url, filter) => {
  let q = "";
  if (filter) {
    let query = new URLSearchParams(filter);
    q = `?${query.toString()}`;
  }

  const r = await axios.get(baseurl + url + q);
  return r.data;
};

export const rawApiGet = async (url, filter) => {
  let q = "";
  if (filter) {
    let query = new URLSearchParams(filter);
    q = `?${query.toString()}`;
  }

  const r = await axios.get(url + q);
  return r.data;
}
