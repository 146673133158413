<template>
  <div class="login">
    <div>
      <form @submit.prevent="login()">
        <p class="logo">
          <img src="https://perekrutan.kidora.co.id/static/kidora.jpg" height="70">
        </p>

        <h1>Login Kidora Perekrutan</h1>

        <div class="form-group">
          <select v-model="param.id">
            <option :value="item.id" v-for="item, index in user">
              {{ item.nama }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input type="password" v-model="param.password">
        </div>
        <div class="form-group">
          <button type="submit" class="btn primary" :disabled="loading">LOGIN</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from '@/api'

export default {
  name: 'LoginView',
  data() {
    return {
      loading: false,

      user: [],
      param: {}
    }
  },
  methods: {
    async load() {
      const res = await apiGet('/v1/user');
      this.user = res.data
    },
    async login() {
      this.loading = true;

      const res = await apiPost('/v1/user', this.param)

      if (res.valid) {
        localStorage.setItem('login', JSON.stringify(res.data))
        location.href = '/';
      } else {
        alert('Login gagal')
        this.loading = false;
      }
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style lang="scss" scoped>
.login {
  background: #f7f7f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  >div {
    background: #fff;
    margin-top: 100px;
    box-shadow: 0px 0px 4px #ccc;
    border-radius: 10px;
    padding: 20px;

    form {
      width: 350px;

      h1,
      .logo {
        text-align: center
      }

      h1 {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
}

form {
  padding: 0 50px;

  .form-group {

    input,
    select,
    button {
      width: 100%;
    }

    input {
      width: calc(100% - 22px);
    }

  }
}
</style>
