<template>
  <div v-if="$route.path == '/login'">
    <LoginView />
  </div>
  <div class="main" v-else>
    <nav>
      <h3>Kidora Perekrutan</h3>
      <ul>
        <li>
          <router-link to="/">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />
            </svg>
            Home
          </router-link>
        </li>
        <li v-if="false">
          <router-link to="/cv">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z" />
            </svg>
            Berkas Siswa
          </router-link>
        </li>
        <li>
          <router-link to="/lowongan">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01" />
            </svg>
            Lowongan
          </router-link>
        </li>
        <li>
          <router-link to="/lamaran">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            Lamaran
          </router-link>
        </li>
        <li>
          <router-link to="/siswa-bekerja">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
            </svg>
            Siswa Bekerja</router-link>
        </li>
      </ul>
      <div class="user">
        <div class="nama">
          <b>{{ user.nama }}</b>
        </div>
        <div class="logout" title="Logout" @click="logout()">
          <img src="https://img.icons8.com/?size=24&id=Q1xkcFuVON39&format=png" height="22">
        </div>
      </div>
    </nav>
    <router-view class="content" />
  </div>
</template>

<script>
import LoginView from '@/views/LoginView';

export default {
  components: {
    LoginView
  },
  data() {
    return {
      user: {}
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/login')
    }
  },
  mounted() {
    if (this.$route.path != '/login') {
      const user = JSON.parse(localStorage.getItem('login'))
      if (!user) {
        this.$router.push('/login')
      } else {
        this.user = user;
      }
    }
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

* {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --text-color: #232323;
  --primary: #ff551e;
  --primary-disabled: #ffa587;
  --green: #ccffce;
}

html,
body {
  margin: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.main {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 10px;
  height: 100vh;
}

h1 {
  font-size: 18px;
}

nav {
  padding: 20px;
  position: relative;

  h3 {
    padding-left: 10px;
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 10px 0;

      a {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        text-decoration: none;
        border-radius: 6px;
        transition: background 0.3s ease;

        img {
          height: 22px;
        }

        &.router-link-active {
          font-weight: bold;
          color: var(--primary);
        }

        &:hover {
          background: #eee
        }
      }
    }
  }
}

.page {
  padding: 20px;
}

.btn-bar {
  display: flex;
  justify-content: space-between;

  >div {
    display: flex;
    gap: 10px;
  }
}

.btn {
  background: #eee;
  padding: 10px 15px;
  border-radius: 6px;
  outline: none;
  cursor: pointer;

  &.primary {
    background: var(--primary);
    border: 1px solid var(--primary);
    color: #fff;

    &:disabled {
      background: var(--primary-disabled);
    }
  }

  &.secondary {
    border: 1px solid #ccc;
  }

  &.outline {
    background: #fff;
    border: 1px solid var(--primary);
    color: var(--primary);
  }
}

.btn-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  input,
  select {
    border: 1px solid #ccc;
    padding: 5px;
    outline: none;

    &:focus {
      border: 1px solid var(--primary);
    }
  }

  .btn {
    padding: 5px 15px;
    border-radius: 4px;
    transition: transform 0.1s ease;

    &:active {
      transform: scale(0.9);
    }
  }
}

.table {
  padding: 0 20px 20px 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  height: calc(100vh - 200px);
  overflow-y: auto;

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0;

      tr {
        th {
          border-bottom: 1px solid #232323;
          text-align: left;
          background: #fff;
        }
      }
    }

    tbody {
      &.loading {
        opacity: 0.5;
      }
    }

    th,
    td {
      padding: 10px;
      cursor: pointer;
    }

    tr {
      transition: background 0.3s ease;

      &:hover {
        background: #f3f3f3;
      }
    }

    .btn-hapus {
      color: #ff551e;
      padding: 4px 8px;
      border-radius: 4px;

      &:hover {
        background: var(--primary);
        color: #fff;
      }
    }
  }
}

.form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 6px;

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      border: 1px solid #ccc;
      padding: 8px;
      border-radius: 6px;
      outline: none;
      background: #fafafa;

      &:focus {
        background: #fff;
        border: 1px solid var(--primary);
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .modal-body {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 100px;
  }
}

.form-group {
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  select {
    border: 1px solid #ccc;
    padding: 10px;
    outline: none;
    border-radius: 5px;

    &:focus {
      border: 1px solid #333;
    }
  }

  &.checkbox {

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    input {
      width: auto;
    }
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #000 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, #000);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: l13 1s infinite linear;
  margin: 60px auto;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}

.user {
  position: absolute;
  bottom: 20px;
  width: 90%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logout {
    cursor: pointer;
  }
}
</style>
