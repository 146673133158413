<template>
  <div class="page">
    <h1>Home</h1>

    <div class="grid">
      <div class="card">
        <div class="card-body">
          <h1>0</h1>
          <p>Lowongan Aktif</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h1>0</h1>
          <p>Siswa Melamar</p>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h1>0</h1>
          <p>Siswa Bekerja</p>
        </div>
      </div>
    </div>

    <br />
    <br />

    <div class="grid grid-2">
      <div>
        <h2>LOWONGAN TERBARU</h2>
        <div class="card">
          <div class="card-body">

            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Pekerjaan</th>
                  <th>Perusahaan</th>
                  <th>PIC</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div>
        <h2>LAMARAN TERBARU</h2>
        <div class="card">
          <div class="card-body">

            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>No Daftar</th>
                  <th>Nama Siswa</th>
                  <th>Pekerjaan</th>
                  <th>Status</th>
                </tr>
              </thead>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  border: 1px solid #ccc;
  border-radius: 10px;

  .card-body {
    padding: 15px;
  }
}

table {
  width: 100%;
}
</style>
