import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cv',
    name: 'cv',
    component: function () {
      return import(/* webpackChunkName: "cv" */ '../views/CvView.vue')
    }
  },
  {
    path: '/lowongan',
    name: 'lowongan',
    component: function () {
      return import(/* webpackChunkName: "lowongan" */ '../views/LowonganView.vue')
    }
  },
  {
    path: '/lowongan/:id',
    name: 'lowongan-detail',
    component: function () {
      return import(/* webpackChunkName: "lowongan-detail" */ '../views/LowonganDetailView.vue')
    }
  },
  {
    path: '/lamaran',
    name: 'lamaran',
    component: function () {
      return import(/* webpackChunkName: "lamaran" */ '../views/LamaranView.vue')
    }
  },
  {
    path: '/siswa-bekerja',
    name: 'siswa-bekerja',
    component: function () {
      return import(/* webpackChunkName: "siswa-bekerja" */ '../views/SiswaBekerjaView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
